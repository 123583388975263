(function () {
    angular.module('informaApp')
        .service('LazyLoadingService', LazyLoadingService);

    function LazyLoadingService() {
        return {
            lazyDraw
        }
    }

    function lazyDraw(scrollContainer, sourceArray, createItem, getYByIndex, getIndexByY, itemHeight, callbackOnScroll = null) {
        let blocks = [];

        blocks.push(...initializeItems(scrollContainer, sourceArray, getIndexByY, createItem));

        bindOnScroll(scrollContainer, sourceArray, blocks, createItem, getYByIndex, getIndexByY, callbackOnScroll, itemHeight);
    }

    function bindOnScroll(scrollContainer, sourceArray, blocks, createItem, getYByIndex, getIndexByY, callbackOnScroll, itemHeight) {
        let scrollOffset = scrollContainer.scrollTop;

        scrollContainer.addEventListener('scroll', () => {
            if (scrollContainer.scrollTop - scrollOffset === 0) {
                return;
            }

            callbackOnScroll && callbackOnScroll();

            const direction = scrollContainer.scrollTop - scrollOffset < 0 ? -1 : 1;
            scrollOffset = scrollContainer.scrollTop;

            onScroll(scrollContainer, sourceArray, blocks, createItem, getYByIndex, getIndexByY, direction, itemHeight);
        });
    }

    function onScroll(scrollContainer, sourceArray, blocks, createItem, getYByIndex, getIndexByY, direction, itemHeight) {
        removeOutOfScrollItems(scrollContainer, blocks, direction, getYByIndex);

        if (blocks.length) {
            createInScrollItems(scrollContainer, sourceArray, blocks, direction, createItem, getYByIndex, itemHeight);
        } else {
            blocks.push(...initializeItems(scrollContainer, sourceArray, getIndexByY, createItem));
        }
    }

    function createInScrollItems(scrollContainer, sourceArray, blocks, direction, createItem, getYByIndex, itemHeight) {
        const newBlocks = direction > 0
            ? getInScrollItems(scrollContainer, sourceArray, blocks, blocks.last().index + 1, direction, isBelowBottomBorder, getYByIndex, itemHeight)
            : getInScrollItems(scrollContainer, sourceArray, blocks, blocks[0].index - 1, direction, isAboveTopBorder, getYByIndex, itemHeight);

        if (newBlocks.length) {
            const createdBlocks = newBlocks.map((x) => createItemWithIndex(createItem, x.data, x.index));

            if (direction > 0) {
                blocks.push(...createdBlocks);
            } else {
                blocks.splice(0, 0, ...createdBlocks);
            }
        }
    }

    function removeOutOfScrollItems(scrollContainer, blocks, direction, getYByIndex) {
        const outOfScrollBlocks = direction > 0
            ? getOutOfScrollItems(scrollContainer, blocks, 0, direction, isAboveTopBorder, getYByIndex)
            : getOutOfScrollItems(scrollContainer, blocks, blocks.length - 1, direction, isBelowBottomBorder, getYByIndex);

        blocks.removeIf(x => outOfScrollBlocks.indexOf(x) >= 0);
        outOfScrollBlocks.forEach(x => x.item.remove());
    }

    function getOutOfScrollItems(scrollContainer, blocks, startIndex, delta, isOutOfScroll, getYByIndex) {
        const outOfScrollBlocks = [];

        for (let i = startIndex; i >= 0 && i < blocks.length; i += delta) {
            const blockBBox = blocks[i].item.element.node().getBBox();
            blockBBox.y = getYByIndex(blocks[i].index);

            if (isOutOfScroll(scrollContainer, blockBBox)) {
                outOfScrollBlocks.push(blocks[i]);
            } else {
                return outOfScrollBlocks;
            }
        }

        return outOfScrollBlocks;
    }

    function getInScrollItems(scrollContainer, sourceArray, blocks, startIndex, delta, isOutOfScroll, getYByIndex, itemHeight) {
        const blocksInScroll = [];

        for (let i = startIndex; i >= 0 && i < sourceArray.length; i += delta) {
            const y = getYByIndex(i);

            if (blocks.find(x => x.index === i)) {
                continue;
            }

            if (!isOutOfScroll(scrollContainer, {y, height: itemHeight})) {
                blocksInScroll.push({index: i, data: sourceArray[i]});
            } else {
                return blocksInScroll;
            }
        }

        return blocksInScroll;
    }
    
    function isAboveTopBorder(scrollContainer, {y, height}) {
        return scrollContainer.scrollTop > y + height;
    }
    
    function isBelowBottomBorder(scrollContainer, {y}) {
        return scrollContainer.scrollTop + scrollContainer.clientHeight < y;
    }

    function initializeItems(scrollContainer, sourceArray, getIndexByY, createItem) {
        const left = getIndexByY(scrollContainer.scrollTop);
        const right = getIndexByY(scrollContainer.scrollTop + scrollContainer.clientHeight);

        const result = [];

        sourceArray.slice(left, right).forEach((x, i) => {
            result.push(createItemWithIndex(createItem, x, left + i));
        });

        return result;
    }

    function createItemWithIndex(createItem, data, index) {
        return {
            index,
            item: createItem(data, index)
        };
    }
})();
